import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Translation } from "react-i18next";
import i18next from "i18next";


class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: i18next.language
    }
  }
  componentDidMount() {
    (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
    // eslint-disable-next-line no-undef
    branch.init('key_live_hoSSEhW38lyfiz9GTuUnZknmqDjsqSg6',function(err, data) {
    
    if(data.data_parsed && data.data_parsed.otherUserID){
      window.location="http://twinklode.com/profile/"+data.data_parsed.otherUserID;
    }
    if(data.data_parsed && data.data_parsed.blog_id){
      window.location="http://twinklode.com/blog/"+data.data_parsed.blog_id;
    }
   
    });
    setTimeout(() => {
      this.setState({ lang: i18next.language });
    }, 500)
  }
  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ lang: i18next.language });
    }, 500)
  }
  render() {
    const langugeCode = this.state.lang;
    var SHARE_YOUR_GOAL = '';
    var SUPPORT_TEXT = '';
    var EXPORE_TEXT = "";
    if (langugeCode === 'en') {
      SHARE_YOUR_GOAL = <h1>Share your <span style={{ color: "#3facff" }}>goal</span> , <span style={{ color: "#16dfb9" }}>achievements</span> and < span style={{ color: "#ff464c" }}
      > ideas</span > with others</h1>
      SUPPORT_TEXT = <h1>Show your <span style={{ color: "#61f6ff" }}> support </span> to people and tap on support many times </h1>
      EXPORE_TEXT = <h1>Explore space , galaxy and <span style={{ color: "#61f6ff" }}>#asteroids</span> engage with people to get inspired and achive goals</h1>
    }
    else {     
      /*SHARE_YOUR_GOAL = <h1> <span style={{ color: "#ff464c" }}> أفكارك </span> أفكارك مع <span style={{ color: "#16dfb9" }}>انجازاتك </span> ,<span style={{ color: "#3facff" }}>أهدافك</span> شارك </h1>;*/

     
      /*SHARE_YOUR_GOAL = <h1>مع الاخرين <span style={{ color: "#ff464c",direction:'rtl' }}> أفكارك </span> و <span style={{ color: "#16dfb9",direction:'rtl' }}> انجازاتك </span> ، <span style={{ color: "#3facff",direction:'rtl' }}> أهدافك </span> شارك</h1>;*/
      SHARE_YOUR_GOAL = <h1>شارك أهدافك ، انجازاتك و أفكارك مع الاخرين</h1>;


      SUPPORT_TEXT = <h1>ادعم وشجع غيرك بالضغط على دعم بتكرار</h1>
      EXPORE_TEXT=  <h1> شارك أصدقائك و وادعمهم لتحقيق أهدافكم <span style={{ color: "#61f6ff" }}>#كويكبات </span>اكتشف الفضاء ، المجرة و </h1>
    }
    return (
      <div>
        <Header />
        <section className="bannerSec vhcenter">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-xs-12 animated fadeInLeft">
                <div className="welcomeText">
                  <Translation>
                    {(t, { i18n }) => <h1>{t("HOME_PAGE.1")}</h1>}
                  </Translation>

                  {/* <Translation>
                    {(t, { i18n }) => (
                      <a
                        href={process.env.REACT_APP_DEEPLINKING}
                        className="followBtn"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("BUTTON.DOWNLOAD_NOW")}
                      </a>
                    )}
                  </Translation> */}
                  <Translation>
                    {(t, { i18n }) => (
                      <a
                        href={process.env.REACT_APP_DEEPLINKING}
                        className="followBtn"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("BUTTON.EXPLORE_FEATURES")}
                      </a>
                    )}
                  </Translation>
                </div>
              </div>              
            </div>
          </div>
        </section>
        <section className="bnr2 vhcenter">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-xs-6">
                <div className="welcomeText goalTitle vhcenter fadeInLeft animated1">
                  <Translation>
                    {(t, { i18n }) => <h1>{t("HOME_PAGE.2")}</h1>}
                  </Translation>
                </div>
              </div>
              <div className="col-sm-6 col-xs-6">
                <div className="rightImg animated2 fadeInRight sectionImg">
                  <img
                    alt=""
                    src={process.env.PUBLIC_URL + "/assets/img/spceman.png"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bnr2 bg3">
          <div className="container">
            <div className="row vhcenter">
              <div className="col-sm-6 col-xs-6 pT10 fadeInLeft animated">
                <img
                  alt=""
                  src={process.env.PUBLIC_URL + "/assets/img/goal.png"}
                  className="sectionImg"
                />
              </div>
              <div className="col-sm-6 col-xs-6 fadeInRight animated1">
                <div className="welcomeText goalTitle vhcenter fadeInRight animated1 ">

                  {SHARE_YOUR_GOAL}

                  {/* <Translation>
                    {(t, { i18n }) => <h1>{t("HOME_PAGE.3")}</h1>}
                 </Translation>*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bnr2 bg4 vhcenter">
          <div className="container">
            <div className="row vhcenter">
              <div className="col-sm-6 col-xs-6 fadeInLeft animated">
                <div className="welcomeText pB25">
                  {/* <Translation>
                    {(t, { i18n }) => <h1>{t("HOME_PAGE.4")}</h1>}
                  </Translation> */}
                   {SUPPORT_TEXT}

                </div>
                {/* <div className="row">
                  <div className="col-sm-6 pB25 bg4-img">
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + "/assets/img/ex1.jpg"}
                    />
                  </div>
                  <div className="col-sm-6 pB25 bg4-img">
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + "/assets/img/ex1.jpg"}
                    />
                  </div>
                  <div className="col-sm-6 bg4-img">
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + "/assets/img/ex1.jpg"}
                    />
                  </div>
                  <div className="col-sm-6 bg4-img">
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + "/assets/img/ex1.jpg"}
                    />
                  </div>
                </div> */}
              </div>

              <div className="col-md-6 col-xs-6 fadeInRight animated">
                <img
                  alt=""
                  src={process.env.PUBLIC_URL + "/assets/img/bg4-right.png"}
                />
              </div>
            </div>
            {/* <div className="row hidden-lg hidden-md hidden-sm">
              <div className="col-xs-6">
                <div className="welcomeText goalTitle fadeInRight animated1 ">
                  <h1>Share your <span style={{color: "#3facff"}}>goal</span> ,  <span style={{color: "#16dfb9"}}>achievements</span> and <span style={{color: "#16dfb9"}}>ideas</span> with others</h1>
                </div>
              </div>
              <div className="col-xs-6 hidden-xs"></div>
            </div> */}
          </div>
        </section>
        <section className="bnr2 bg5 vhcenter">
          <div className="container">
            <div className="row vhcenter">
              <div className="col-sm-6 col-xs-6 fadeInLeft animated">
                <img
                  alt=""
                  src={process.env.PUBLIC_URL + "/assets/img/explore-bg.png"}
                />

              </div>
              <div className="col-sm-6 col-xs-6 fadeInRight animated">
                <div className="welcomeText bgright vhcenter">
                  {/* <Translation>
                    {(t, { i18n }) => <h1>{t("HOME_PAGE.7")}</h1>}
                  </Translation> */}
                  {EXPORE_TEXT}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="contactUs bnr2 hidden-xs">
          <div className="container">
            <div className="contactinr">
              <div className="welcomeText text-center fadeInDown animated">
                <Translation>
                  {(t, { i18n }) => <h1>{t("HOME_PAGE.6")}</h1>}
                </Translation>
              </div>
              <Contact />
            </div>
          </div>
        </section> */}
        <Footer />
      </div>
    );
  }
}

export default Home;
