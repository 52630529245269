import React from "react";
import { Translation } from "react-i18next";
import i18next from "i18next";

class Footer extends React.Component {
  handleClick(lang) {
    i18next.changeLanguage(lang);
  }

  render() {
    return (
      <footer>
        <div className="footer-icon">
          <img alt="" src={process.env.PUBLIC_URL + "/assets/img/f-logo.png"} />
        </div>
        <Translation>
          {(t, { i18n }) => <div className="copy">{t("FOOTER_COPYRIGHT")}. <a style={{color:"white"}} href="/tos">Terms Of Service</a></div>}
        </Translation>

      </footer>
    );
  }
}

export default Footer;
