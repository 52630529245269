import React from "react";
import axios from "axios";
import qs from "query-string";
import Moment from "react-moment";
import { Translation } from "react-i18next";

class Postlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.props.lang,
      loading: true,
      posts: [],
      user_id: this.props.userid,
      blog_userid: this.props.userid,
      error: "",
      hideshow_postlist: this.props.isToggleOn
    };
    this.getblogListdata(this.props.userid, this.props.isToggleOn);
  }

      getblogListdata (userid, year){
        var user_id = userid;
        var blog_userid = userid;
        var webyear = year;
              if(webyear !==1){
              var self = this;
              axios
                .post(
                  process.env.REACT_APP_API_BLOG_DETAILS,
                  qs.stringify({
                    user_id,
                    blog_userid,
                    webyear
                  })
                )
                .then(function(response) {
                  if (response.data.status === "success") {
                    self.setState({ posts: response.data.bloglist });
                  }
                })
                .catch(function(error) {
                  self.setState({ error: error });
                });
              }
      }
  componentWillReceiveProps(props) {
    this.setState({ 
      hideshow_postlist: props.isToggleOn,
      lang: props.lang
    
    });
    this.getblogListdata(props.userid,props.isToggleOn);
  }

  convertUnicode(input) {
    return input.replace(/\\u(\w\w\w\w)/g, function(a, b) {
      var charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    });
  }





  getyearFromblogDate(blogdate) {
    var dArray = blogdate.split("-");
    var dyear = dArray[0];
    dyear = dyear.toString();
    var postYearSelected = this.state.hideshow_postlist;
    postYearSelected = postYearSelected.toString();
   
    if (postYearSelected === dyear) {
      return " showPostTw " + dyear;
    } else {
      return "hidePostTw " + dyear;
    }
  }

  replacePercent(str) {
    if (str) {
      return str.replace("%", "");
    }
  }

  render() {
    var postList = this.state.posts.map((p, n) => (
      <React.Fragment key={n} >
       <a href={`/history/${p.user_id}/${p.id}`}>
        <div
          key={p.id}
          className={`col-sm-6 ${this.getyearFromblogDate(p.blog_date)}`}
        >
          <div className="twinklodeBlock">
            <div className="top_div">
              <div className="twinkuser">
                {p.avatar ? (
                  <img
                    alt=""
                    src={`${process.env.REACT_APP_UPLOAD_DIRECTORY}${p.avatar}`}
                  />
                ) : (
                  <img
                    alt=""
                    src={process.env.PUBLIC_URL + "/assets/img/no-profile.png"}
                  />
                )}
              </div>
              <div className="twinkuser_name">
                {this.convertUnicode(p.username)}
            {p.blog_type === '1' ? (
<Translation>
{(t, { i18n }) => (
 <span className="achievment green">{t("ACHIV")}  <i className="blue">  
 <img
   alt=""
   width="10px"
   src={process.env.PUBLIC_URL + "/assets/img/achievment-icon.png" }
 /> </i>
 </span>
)}
</Translation>
              )
              : ('')
              }
              {p.blog_type === '3' ? (
<Translation>
{(t, { i18n }) => (
 <span className="achievment red">{t("IDEA")} <i className="red">  
 <img
   alt=""
   width="10px"
   src={process.env.PUBLIC_URL + "/assets/img/idea-icon.png" }
 /> </i>
 </span>
)}
</Translation>
              )
              : ('')
              }
              {p.blog_type === '2'  ? (
<Translation>
{(t, { i18n }) => (
 <span className="achievment blue">{t("GOAL")} <i className="blue">  
 <img
   alt=""
   width="10px"
   src={process.env.PUBLIC_URL + "/assets/img/flag-icon.png" }
 /> </i>
 </span>
)}
</Translation>
              )
              : ('')
              }
              </div>
              <div className="rating_value">
                <img
                  alt=""
                  width="36"
                  src={process.env.PUBLIC_URL + "/assets/img/clap-icon.png"}
                />
                <span className="ratingNo">{p.supportcount}</span>
              </div>
            </div>
            <div className="block_detail">
              <h2>{this.convertUnicode(p.title)}</h2>
              
              
              <div className="date">
              <Translation>
{(t, { i18n }) => (
 <React.Fragment >{t("due_date")} </React.Fragment>
)}
</Translation>{" "}
                <Moment format="DD MMM YYYY" parse="YYYY-MM-DD">
                  {p.blog_date}
                </Moment>
              </div>
              <p>{this.convertUnicode(p.description)}</p>
              <ul className="detailImg tempHide">
                {p.media.map((m, md) => (
                  <li key={md}>
                    <div className="list-img">
                      <img
                        alt={m.image}
                        width="60"
                        height="40"
                        src={`${process.env.REACT_APP_AWS_S3}${m.thumb}`}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mediaBlock"> 
                {  p.media.map((m, md) => (
                      <div key={md} className="mediaListing" style={{ backgroundImage:`url(${process.env.REACT_APP_AWS_S3}${m.thumb})` }}> 
                      </div>
                  ))}
            </div>
            <div className="profilemanageblock new">
              <div className="spacemanagement">
                {p.category.map((c, cn) => (
                  <div key={cn}>
                    <span className="manageImg">
                      <img
                        height="28"
                        width="28"
                        alt="cat"
                        src={`${process.env.REACT_APP_UPLOAD_DIRECTORY}${c.cat_image}`}
                      />
                    </span>
                    {this.state.lang == 'ar'? (
                    <React.Fragment >{c.cat_name_ar}</React.Fragment >
                    ) : (
                      <React.Fragment > {c.cat_name}</React.Fragment >
                    ) }
                  </div>
                ))}
              </div>
              <div className="w-100">
              <div className="progressBar">
      <div className="progress"> 
              {p.blog_type === '1' ? (
              <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="40"
              aria-valuemin="0" aria-valuemax="100" style={{width: this.replacePercent(p.blog_progress) +"%"}} >
              </div>
              )
              : ('')
              }
              {p.blog_type === '3' ? (
                <div className="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100" style={{width: this.replacePercent(p.blog_progress) +"%"}} >
                </div>
              )
              : ('')
              }
              {p.blog_type ===  '2' ? (
              <div className="progress-bar progress-bar-progress" role="progressbar" aria-valuenow="40"
              aria-valuemin="0" aria-valuemax="100" style={{width: this.replacePercent(p.blog_progress) +"%"}} >
              </div>
              )
              : ('')
              }
      </div>
      </div>  
              </div>
              <div className="spaceprogress">
                <span>{this.replacePercent(p.blog_progress)}%</span> 
               {p.blog_type === '1' ? (
                    <font className="green"> Done</font>
              )
              : ('')
              }
              {p.blog_type === '3' ? (
                <font style={{color:'#ff3333'}}> Need Support</font>
              )
              : ('')
              }

              {p.blog_type ===  '2'  ? (
                  <font className="blue"> In Progress</font>
              )
              : ('')
              }
              </div>
            </div>
            <ul className="share_like">
              <li>
                <a href={`/history/${p.user_id}/${p.id}`}>
                  <span>
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + "/assets/img/eye.png"}
                    />
                  </span>{" "}
                  {p.views}
                </a>
              </li>
              <li>
                <a href={`/history/${p.user_id}/${p.id}`}>
                  <span>
                    {p.favstatus === 1 ? (
                     <i class="zmdi zmdi-star"></i>
                    ) : (
                      
                      <i class="zmdi zmdi-star-outline"></i>
                    )}
                  </span>{" "}
                  {p.favcount}
                </a>
              </li>
              <li>
                <a href={`/history/${p.user_id}/${p.id}`}>
                  <span>
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + "/assets/img/comment.png"}
                    />
                  </span>{" "}
                  {p.blogcount}
                </a>
              </li>
              <li>
                <a href={`/history/${p.user_id}/${p.id}`}>
                  <span>
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + "/assets/img/share.png"}
                    />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        </a> 
      </React.Fragment>
    ));
    return (
      <div>
        {this.state.loading ? <div>{postList}</div> : <div>Person..</div>}
      </div>
    );
  }
}
export default Postlist;