import React from "react";
import Headerhistory from "./Headerhistory";
import Footer from "./Footer";
import axios from "axios";
import qs from "query-string";
import Moment from "react-moment";
import i18next from "i18next";


class History extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mydata: {
        category: [],
        media: []
      },
      fullname: "",
      commentdata: {
        comment: []
      },
      user_id: props.match.params.username,
      blog_id: props.match.params.blogid,
      error: "",
      commentdataerror: "",
      lang: i18next.language
    };

    var self = this;

    var user_id = props.match.params.username;
    var blog_id = props.match.params.blogid;

    axios
      .post(
        process.env.REACT_APP_API_BLOGDETAIL,
        qs.stringify({
          user_id,
          blog_id
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      )
      .then(function(response) {
        if (response.data.status === "success") {

          self.setState({ mydata: response.data.blogdetail });
        } else {
          self.setState({
            error: "something went wrong. Try again in a few minutes"
          });
        }
      })
      .catch(function(error) {
        self.setState({ error: error });
      });

    axios
      .post(
        process.env.REACT_APP_API_GETCOMMENT,
        qs.stringify({
          blog_id
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      )
      .then(function(response) {
        if (response.data.status === "success") {
          self.setState({ commentdata: response.data });
          this.setState({ lang: i18next.language });
        } else {
          self.setState({
            commentdataerror: "something went wrong. Try again in a few minutes"
          });
        }
      })
      .catch(function(error) {
        self.setState({ commentdataerror: error });
      });
  }

  componentDidMount() {
    document.body.classList.add("innerBg");
  }

  convertUnicode(input) {
    if (input) {
      return input.replace(/\\u(\w\w\w\w)/g, function(a, b) {
        var charcode = parseInt(b, 16);
        return String.fromCharCode(charcode);
      });
    }
  }

  replacePercent(str) {
    if (str) {
      return str.replace("%", "");
    }
  }

  render() {


    let userImage;
    if (this.state.mydata.avatar) {
      userImage = (
        <div className="profileImg">
          <img
            alt=""
            src={`${process.env.REACT_APP_UPLOAD_DIRECTORY}${this.state.mydata.avatar}`}
          />{" "}
        </div>
      );
    } else {
      userImage = (
        <div className="profileImg">
          <img
            alt=""
            src={process.env.PUBLIC_URL + "/assets/img/no-profile.png"}
          />{" "}
        </div>
      );
    }



    return (
      <div>
        <Headerhistory />
        <div className="postTwink">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="postTwink_leftsect">
                  <div className="ratedStar">
                    <div className="star">
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + "/assets/img/star.png"}
                      />
                    </div>
                    <div className="userRating">
                      {this.state.mydata.supportcount}
                    </div>
                  </div>

                  {this.state.mydata.category.map((c, cn) => (
                    <div className="manage" key={cn}>
                      <span className="manageImg">
                        <img
                          height="28"
                          width="28"
                          alt="cat"
                          src={`${process.env.REACT_APP_UPLOAD_DIRECTORY}${c.cat_image}`}
                        />
                      </span>
                      {c.cat_name}
                    </div>
                  ))}

                  <div className="commentDate slideComment">
                    <Moment format="DD MMM YYYY" parse="YYYY-MM-DD">
                      {this.state.mydata.blog_date}
                    </Moment>
                  </div>

                  <div className= {`historyslider ${"single-item-"+this.state.lang}`}   id="home">
                    <div className="slide">
                      <div className="slide_img">
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL + "/assets/img/slide1.jpg"
                          }
                        />
                        <div className="bnr_text">
                        {userImage}
                          <h1>
                            {this.convertUnicode(this.state.mydata.fullname)}
                          </h1>
                          <p>{this.convertUnicode(this.state.mydata.title)}</p>
                          <div className="progressText">
                            <span>
                              {this.replacePercent(
                                this.state.mydata.blog_progress
                              )}
                              %
                            </span>{" "}
                            In progress
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="slide">
                      <div className="slide_img">
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL + "/assets/img/slide1.jpg"
                          }
                        />
                        <div className="bnr_text">
                        {userImage}
                          <h1>
                            {this.convertUnicode(this.state.mydata.fullname)}
                          </h1>
                          <p>{this.convertUnicode(this.state.mydata.title)}</p>
                          <div className="progressText">
                            <span>
                              {this.replacePercent(
                                this.state.mydata.blog_progress
                              )}
                              %
                            </span>{" "}
                            In progress
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div className="col-sm-6">
                <div className="history_outer">
                  <div className="post_history">
                    <div className="historyTop">
                      <span className="top_title">
                        <i className="zmdi zmdi-star"></i> Twinkle History
                      </span>

                      <div className="postInfo">
                        <div className="datePost">
                          <Moment format="DD MMM YYYY" parse="YYYY-MM-DD">
                            {this.state.mydata.blog_date}
                          </Moment>
                        </div>
                        <div className="postDetail">
                          <p>
                            {this.convertUnicode(this.state.mydata.description)}
                          </p>
                          <div className="createInfo">
                            <div className="row">
                              <div className="col-sm-7 col-xs-7 createdDate">
                                Created on{" "}
                                <Moment format="DD MMM YYYY" parse="YYYY-MM-DD">
                                  {this.state.mydata.blog_date}
                                </Moment>
                              </div>
                              <div className="col-sm-5 col-xs-5 createProgress">
                                {this.replacePercent(
                                  this.state.mydata.blog_progress
                                )}
                                % in progress
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="shareDiv">
                      <li>
                        <a href="#!">
                          <span>
                            <i className="zmdi zmdi-eye"></i>
                          </span>{" "}
                          {this.state.mydata.views}
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <span>
                            {this.state.mydata.favstatus === 1 ? (
                              <i className="zmdi zmdi-favorite"></i>
                            ) : (
                              <i className="zmdi zmdi-favorite-outline"></i>
                            )}
                          </span>{" "}
                          {this.state.mydata.favcount}
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <span>
                            <i className="zmdi zmdi-comment-outline"></i>
                          </span>{" "}
                          {this.state.mydata.blogcount}
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <span>
                            <i className="zmdi zmdi-share"></i>
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div className="comment">
                      <div className="top_title">Comments</div>

                      {this.state.commentdata.comment.slice(0,2).map((c, cn) => (
                      
                       
                       <div className="userComment" key={cn}>
                          <div className="userImg">
                            {c.avatar ? (
                              <img
                                alt="profile"
                                src={`${process.env.REACT_APP_UPLOAD_DIRECTORY}${c.avatar}`}
                              />
                            ) : (
                              <img
                                alt="profile"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/no-profile.png"
                                }
                              />
                            )}
                          </div>

                          <div>
                            <p>
                              <span className="comntName">
                                
                                {this.convertUnicode(c.username)}
                              </span>{" "}
                              {this.convertUnicode(c.comment)}
                            </p>
                            <div className="reply">Reply</div>
                          </div>
                        </div>



                      ))}
                    </div>
                  </div>
                </div>
              </div>
                              





            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default History;
