import React from "react";
import axios from "axios";
import qs from "query-string";
import Header from "./Header";
import Footer from "./Footer";
import Postlist from "./Postlist";
import Moment from "react-moment";
import { Translation } from "react-i18next";
import i18next from "i18next";

import avtarImagePlaceholder from "./img/avtar-image.png";

class Profile extends React.Component {
  constructor(props) {
    super(props);

   
    this.state = {
      mydata: {
        category: [],
        username: "",
        fullname: "",
        favoritequote: ""
      },
      userid: props.match.params.username,
      error: "",
      yeardata: {
        blogdates: []
      },
      yearerror: "",
      isToggleOn: 1,
      lang: localStorage.getItem("twLanguage")
    };
    this.handleClick = this.handleClick.bind(this);
    var user_id = props.match.params.username;
    var login_id = "";
    var self = this;
    axios
      .post(
        process.env.REACT_APP_API_USER_DETAILS,
        qs.stringify({
          user_id,
          login_id
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      )
      .then(function (response) {
        self.setState({ mydata: response.data.details });
      })
      .catch(function (error) {
        self.setState({ error: error });
      });

    axios
      .post(
        process.env.REACT_APP_API_GET_BLOG_DATES,
        qs.stringify({
          user_id
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      )
      .then(function (response) {
        self.setState({ yeardata: response.data });
        self.setState({ isToggleOn: response.data.blogdates[0].year });
      })
      .catch(function (error) {
        self.setState({ yearerror: error });
      });
  }

  handleClick(v) {
    this.setState(state => ({
      isToggleOn: v
    }));
  }

  convertUnicode(input) {
    return input.replace(/\\u(\w\w\w\w)/g, function (a, b) {
      var charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    });
  }

  state = {
    yearClass: 'yearblock',
};



componentDidUpdate() {
    setTimeout(() => {
      var lang = localStorage.getItem("twLanguage");
      if(this.state.lang !== lang){
          this.setState({ lang: lang });
      }
    }, 2000);
}



componentDidMount() {
  window.addEventListener('scroll', () => {
      let yearClass = 'yearblock';
      if (window.scrollY >= 550) {
          yearClass = 'yearblock stickyYears';
      }
      this.setState({yearClass});
  });
};

  render() {
    
    const dataCat = this.state.mydata.category;
    const yearData = this.state.yeardata.blogdates;
    let userImage;
    if (this.state.mydata.profile_image) {
      userImage = (
        <div className="profileImg"  style={{ backgroundImage:`url(${process.env.REACT_APP_UPLOAD_DIRECTORY}${this.state.mydata.profile_image})` }}>
        </div>
      );
    } else {
      userImage = (
        <div className="profileImg"  style={{ backgroundImage:`url(${avtarImagePlaceholder})` }}  >
        </div>
      );
    }

    const listItems = dataCat.map((d, n) => (
      <div key={n} className="col-md-2">
        <div className="categorybox">
          <div className="twinkImg">
            <img
              alt=""
              src={`${process.env.REACT_APP_UPLOAD_DIRECTORY}${d.cat_image}`}
            />
          </div>  

{this.state.lang == 'ar'? (<div className="typeName">{d.cat_name_ar}</div>) : (<div className="typeName"> {d.cat_name}</div>) }

        </div>
      </div>
    ));
    var yearDataItems = "";
    if (this.state.yeardata.status === "success") {
      yearDataItems = yearData.map((d, n) => (
        <div key={n}>
          <div
            className="yeasrchip"
            value={d.year}
            onClick={() => this.handleClick(d.year)}
          >
            {d.year}
          </div>
        </div>
      ));
    }

    var shineScore = this.state.mydata.shine_score;
    var colorstyle = "whitestar";
    shineScore = parseInt(shineScore);
    if (shineScore > 10000000) {
      colorstyle = "gold";
    } else if (shineScore > 1000000) {
      colorstyle = "silver";
    } else if (shineScore > 100000) {
      colorstyle = "blue";
    } else if (shineScore > 10000) {
      colorstyle = "sky";
    } else if (shineScore > 1000) {
      colorstyle = "green";
    } else if (shineScore > 100) {
      colorstyle = "yellow";
    } else {
      colorstyle = "whitestar";
    }
    const { yearClass } = this.state;
    return (
      <div>
        <Header />
        <section className="bannerSec mainbnr backgroundBgImg newProfile">
          <div className="container">
            <div className="row">
                <div className="col-md-6">
                <div className="userProfile_Sec">
              {userImage}
              <div className="profile_detail">
                <h2>{this.convertUnicode(this.state.mydata.fullname)}  <i className={`starcolor zmdi zmdi-star ${colorstyle}`}></i></h2>
            
                <div className="userId"> @{this.state.mydata.username}</div>
                <Translation>
              {(t, { i18n }) => (
               <div className="score">
               {t("shine_score")} {this.state.mydata.shine_score}
               </div>
              )}
            </Translation>

              </div>
              
            </div>

                </div>







                <div className="col-md-5 col-lg-offset-1">

            <div className="userBars">
                    <div className="userFollowers">
                    {this.state.mydata.blogcount}
                    <Translation>
              {(t, { i18n }) => (
                <p> {t("Twinkles")}</p>
              )}
            </Translation>
                    </div>
                    <div className="userFollowers">
                    {this.state.mydata.followercount}
                    <Translation>
              {(t, { i18n }) => (
                <p> {t("Followers")}</p>
              )}
            </Translation>
                    </div>
                    <div className="userFollowers">
                    {this.state.mydata.followingcount}
                    <Translation>
              {(t, { i18n }) => (
                <p> {t("FOLLOWING")}</p>
              )}
            </Translation>
                    </div>
                    <div className="userFollowers">
                    {this.state.mydata.supportcount}
                    <Translation>
              {(t, { i18n }) => (
                <p> {t("SUPPORTS")}</p>
              )}
            </Translation>
                    </div>

                </div>
                


                <Translation>
              {(t, { i18n }) => (
                <a
                target="_blank" rel="noopener noreferrer"
                href={
                  "http://mymeetingdesk.com:11000/deeplinking.html?" + this.state.userid
                }
                className="followBtn w-100"
              >
                {t("follow")}
              </a>
              )}
            </Translation>




                </div>
            </div>
             
          </div>
        </section>
        <section className="twinkType new">
          
         <div className="container">
         <div className="twinkInr">
            <div className="row no-wrap">{listItems}</div>
          </div>
         </div>
       
      </section>
        <section>

        
                

       




          <div className="visionTOp newColor">
          <img
                      alt=""
                      width="20"
                      className="mr25"
                      src={process.env.PUBLIC_URL + "/assets/img/anouncement-icon.png"}
                    /><Translation>
{(t, { i18n }) => (<span>{t("vision")}</span>)}</Translation> {this.convertUnicode(this.state.mydata.favoritequote)}
          </div>
        </section>
        <section className="more_twinklode new">
          <div className="container more_twinklode_container">
            <div className={yearClass}>{yearDataItems}</div>
            <Postlist
              userid={this.state.userid}
              isToggleOn={this.state.isToggleOn}
              lang={this.state.lang}
            />
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Profile;
