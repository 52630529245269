import React from "react";
import Home from "./Home";
import History from "./History";
import Blog from "./Blog";
import Notfound from "./Notfound";
import Profile from "./Profile";
import { Route, Switch } from "react-router-dom";
import i18next from "i18next";
import Tos from './Tos';

class App extends React.Component {
  constructor(props) {
    super(props);
    var styleTag = document.getElementById("styleMain");
    var htmlTag = document.getElementById("htmlMain");

    var lang = localStorage.getItem("twLanguage");

    if (lang === "ar") {
      styleTag.setAttribute("href", "/assets/css/style_ar.css");
      htmlTag.setAttribute("dir", "rtl");
    }
    if (lang === "en") {
      styleTag.setAttribute("href", "#");
      htmlTag.setAttribute("dir", "ltr");
    }
    i18next.changeLanguage(lang);
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/history/:username/:blogid" component={History} />
          <Route path="/blog/:blogid" component={Blog} />
          <Route path="/Profile/:username" component={Profile} />
          <Route path="/tos" component={Tos} />
        </Switch>
      </div>
    );
  }
}

export default App;
