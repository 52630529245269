import React from "react";
import { Translation } from "react-i18next";
import i18next from "i18next";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick(lang) {
    var styleTag = document.getElementById("styleMain");
    var htmlTag = document.getElementById("htmlMain");

    if (lang === "ar") {
      styleTag.setAttribute("href", "/assets/css/style_ar.css");
      htmlTag.setAttribute("dir", "rtl");
      localStorage.setItem("twLanguage", "ar");
    }
    if (lang === "en") {
      styleTag.setAttribute("href", "#");
      htmlTag.setAttribute("dir", "ltr");
      localStorage.setItem("twLanguage", "en");
    }
    i18next.changeLanguage(lang);
  }
  render() {
    return (
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-xs-4">
              <a href="/">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
                  className="logo"
                  alt=""
                />
              </a>
            </div>
            <div className="col-sm-8 col-xs-8">
              <div className="header-right">
                <ul className="menuDesk">
                  <li className="visible-xs visible-sm">
                    <Translation>
                      {(t, { i18n }) => (
                        <a
                          href={process.env.REACT_APP_DEEPLINKING}
                          className="downloadBtn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("BUTTON.DOWNLOAD_NOW")}
                        </a>
                      )}
                    </Translation>
                  </li>

                  <li>
                    <nav className="langNavbar">
                      <button
                        className="engLang"
                        onClick={() => this.handleClick("en")}
                      >
                        English
                      </button>
                      <button
                        className="arbLang"
                        onClick={() => this.handleClick("ar")}
                      >
                        Arabic
                      </button>
                    </nav>
                  </li>                                                  
                  <li className="hidden-xs hidden-sm">
                    <a href="https://apps.apple.com/sa/app/twinklode/id1053142886" target="_blank">                  
                      <img
                      src={process.env.PUBLIC_URL + "/assets/img/app-store-img.png"}
                      className="appBtn"
                      alt=""
                      />  
                    </a></li>
                  <li className="hidden-xs hidden-sm">
                    <a href="https://play.google.com/store/apps/details?id=com.twinklode" target="_blank">                   
                      <img
                      src={process.env.PUBLIC_URL + "/assets/img/google-play-img.png"}
                      className="appBtn"
                      alt=""
                    />    
                    </a></li>
                  {/* <li>
                    <nav className="langNavbar">
                      <button
                        className="engLang"
                        onClick={() => this.handleClick("en")}
                      >
                        English
                      </button>
                      <button
                        className="arbLang"
                        onClick={() => this.handleClick("ar")}
                      >
                        Arabic
                      </button>
                    </nav>
                  </li> */}
                </ul>
                {/* <div className="mobileMenu">
                  <a className="nav" href="#!">
                    <span></span>
                  </a>
                  <div className="navigation-part">
                    <ul>                                            
                      <li>
                        <a href="#!">
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/img/app-stor-img.png"
                            }
                            className="appBtn"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/img/google-play-img.png"
                            }
                            className="appBtn"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
