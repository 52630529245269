import React from "react";
import axios from "axios";
import qs from "query-string";
import Header from "./Header";
import Footer from "./Footer";
// import { Translation } from "react-i18next";
import i18next from "i18next";


class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: i18next.language,
      mydata: {
        htmlData: "",
        headingData: "",
      }
    }
    var self = this;
    axios
      .get(
        process.env.REACT_APP_API_TOS,
        qs.stringify(),
        {
          headers: {
            // "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      )
      .then(function (response) {
        // console.log("response");
        // console.log(response);
        self.setState({ mydata: response.data });
      })
      .catch(function (error) {
        self.setState({ error: error });
      });
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ lang: i18next.language });
    }, 500)
  }
  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ lang: i18next.language });
    }, 500)
  }
  render() {
    // console.log("this.state.mydata");
    // console.log(this.state.mydata);
    const langugeCode = this.state.lang;
    return (
      <div>
        <Header />
        <section style={{height: "auto" , backgroundColor: "#04032d", backgroundImage:"none"}}  className="bannerSec vhcenter">
          <div style={{marginTop: "100px"}} className="container" >
            <div className="row">
              <div className="col-sm-12 col-xs-12 animated fadeInLeft">
               <div class="welcomeText" style={{maxWidth: "100%"}}>
                <h1>{this.state.mydata.headingData}</h1>

                <div style={{color:"white"}} dangerouslySetInnerHTML={{__html: this.state.mydata.htmlData.replace(/(<? *script)/gi, 'illegalscript')}} >
                </div>


                </div>
              </div>              
            </div>
          </div>
        </section>
        
        <Footer />
      </div>
    );
  }
}

export default Home;
