import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Translation } from "react-i18next";

class Notfound extends React.Component {
  componentDidMount() {
    document.body.classList.add("innerBg");
  }

  render() {
    return (
      <div>
        <Header />

        <div className="postTwink">
          <section className="selected">
            <Translation>
              {(t, { i18n }) => (
                <div className="visionTOp">{t("PAGE_NOT_FOUND")}</div>
              )}
            </Translation>
          </section>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Notfound;
